<template>
  <div class="leaflet-page">
    <img src="//download-cos.yofish.com/ad-gongjushiyebu/20220607153821569-bg.jpg" alt="">
    <div class="submit-btn" @click="applyAction">立即申请</div>
  </div>
</template>
<script>
import { huaxiaDataSubmitApi } from '@/api/base1';

export default {
  name: 'HuaxiaBank3',
  components: {},
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    async jumpHuaxia() {
      let res;
      const { query } = this.$route;
      const params = {
        source: 'yofish',
        linkIndex: query.linkIndex,
      };
      try {
        res = await huaxiaDataSubmitApi(params);
      } catch (e) {
        console.log(e);
      }
      if (res?.code === '1') {
        let linkUrl = res.data;
        window.open(linkUrl, '_self');
      } else {
        this.$toast(res?.desc || '网络异常');
      }
    },
    applyAction() {
      this.jumpHuaxia();
    },
  },
};
</script>
<style lang="scss" scoped>
.leaflet-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(0deg, #C51D31, #E83C48);
  img {
    width: 100%;
  }
  .submit-btn {
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    bottom: 78px;
    width: 698px;
    height: 72px;
    background: linear-gradient(90deg, #F1CD6F, #EDB651);
    border-radius: 36px;
    text-align: center;
    line-height: 72px;
    font-size: 42px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-shadow: -1px -1px 1px rgba(103, 22, 17, 0.3);
    margin-top: -60px;
    position: relative;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
